@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");

body {
  background-color: black;
  text-align: center;
}

* {
  font-family: "Abel", sans-serif;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}
.about {
  background-color: black;
  color: white;
  & > h1 {
    margin-bottom: 30px;
  }
  h2 {
    margin-top: 25px;
  }
  p {
    font-size: 1.2rem;
  }
  a {
    color: #fff;
  }
}
.aboutImage {
  img {
    all: unset;
    height: 60px;
    padding: 10px;
  }
}

.contact-form {
  color: white;
  background-color: black;
  padding-top: 10vh;
  padding-bottom: 10vh;
  margin-top: 20px;
  margin: 0 auto;
  width: 50%;
  p {
    text-align: initial;
    width: fit-content;
    padding: 0;
    margin: 0 auto;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.form-text {
  h2 {
    font-size: 25pt;
    font-weight: 500;
  }
}

.form-input {
  border-radius: 10px;
  border: none;
  background-color: #161616;
  text-align: left;
  width: 80%;
  margin-bottom: 1rem;
  padding: 0.8em;
  color: white;
  font-size: 1em;
  &::placeholder {
    color: white;
  }
  &:focus {
    background-color: white;
    color: black;
    outline: none;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.8em;
  }
}

.btn {
  background-color: #fa788d;
  border-radius: 5px;
  border: none;
  padding: 0.5rem 2rem;
  font-size: 1.4rem;
  font-weight: 200;
  &:focus {
    border: none;
    background-color: #54dcf2;
    outline: none;
  }
  &:hover {
    border: none;
    background-color: #54dcf2;
  }
}

.star {
  cursor: pointer;
}

.star_container {
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.loader {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto 0;
}

.loader-container {
  /* to make it center: https://stackoverflow.com/questions/6256043/css-position-loading-indicator-in-the-center-of-the-screen */
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
