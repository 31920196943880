.registration-pane {
  margin: 30px auto;
  width: 350px;
  color: black;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 24px 10px !important;
  background-color: rgb(250, 251, 252);
}

.registration-button {
  background-color: rgb(46, 188, 79);
  border-color: rgb(46, 188, 79);
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  margin: 10px 0;
}

.registration-attribute{
  display: inline-flex;
  margin: 5px 0 10px;
}